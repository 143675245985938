import React, { useState, useEffect } from 'react'
// import { Link } from "react-router-dom"

let search = window.location.search
var urla;
if (search) {
  var cat1 = search.split("=")[1]
  var cat = cat1.replace('%20', ' ')
  if (cat1 == null) {
    cat = "Best Sellers";
  }
  if (cat === 'PC All%20in%20One') {
    cat = "PC All in One"
  }
  if (cat === 'Best%20sellers') {
    cat = "Best Sellers"
  }
  console.log(cat);
  urla = "https://apis.stefanocoggiola.com/categoria/" + cat + "";
}
else
{
  // urla = "https://apis.stefanocoggiola.com/amazon";
  urla = "https://apis.stefanocoggiola.com/categoria/Best Sellers";
}

const Amazon = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          urla
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setData(actualData);
        setError(null);
      } catch(err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }  
    }
    getData()
  }, [])

  return (
    <div className="App">
      <h1>60 e più prodotti tecnologici selezionati per te su Amazon</h1>
      {loading && <div>Un momento per favore...</div>}
      {error && (
        <div>{`Si è verificato un problema nel caricamento dei dati. Per favore riprova o segnalami l'errore - ${error}`}</div>
      )}
     <div className="row m-3">
          <div className="col-md-12 text-center">
              <ul className="list-inline">
              {/* <li className="list-inline-item"><a href="/" className="menu">TUTTI</a></li> */}
              <li className="list-inline-item"><a href="/?cat=Best Sellers" className="menu">I PI&Ugrave; VENDUTI!</a></li>
              <li className="list-inline-item"><a href="/?cat=Accessori" className="menu">Accessori</a></li>
              <li className="list-inline-item"><a href="/?cat=Monitor" className="menu">Monitor</a></li>
              <li className="list-inline-item"><a href="/?cat=PC Desktop" className="menu">PC Desktop</a></li>
              <li className="list-inline-item"><a href="/?cat=PC All in One" className="menu">PC All in One</a></li>
              <li className="list-inline-item"><a href="/?cat=Notebook" className="menu">Notebook</a></li>
              <li className="list-inline-item"><a href="/?cat=E-books" className="menu">E-Books</a></li>
              </ul>
          </div>
      </div><h2 className="uppercase">{cat}</h2>
      {/* <div className="flex-container">
        {data &&
          data.map(({_id, code }) => (
            <div key={_id} className="flex-item">
               <div className="cardProdotto" key={_id} dangerouslySetInnerHTML={{ __html: code }} >
                </div>               
            </div>
          ))}
      </div> */}
      <div className="row carda">
        {data &&
          data.map(({_id, title, price, foto, link, cat, sub, offerta, offPrice }) => (            
            <div className="col-xs-6 col-sm-3 col-md-3 col-lg-2 cardProdotto" key={_id}>
                {/* <div className={'topoffer-' + offerta}><span className={'txtoffer-' + offerta}>IN OFFERTA OGGI!</span></div> */}
                <div class="titolone">
                  <h5><a href={link} target="_blank" rel="noreferrer" title={title}>{title.substring(0, 48)}...</a></h5>
                </div>
                <div class="quadrato300">
                  <a href={link} target="_blank" rel="noreferrer">
                  {foto ? (
                      <img src={foto} className="fotoCorso" alt={title} title={title} />
                  ) : (
                      <img src="/empty-img.jpg" className="fotoCorso" alt={title} title={title} />
                  )}</a>
                </div>
                <br /> <br />
                Categoria: <b>{cat}</b>
                <div class="row">
                  <div class="col-md-6">
                    {offPrice ? (
                      <h6 className="offerta">{offPrice.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</h6>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div class="col-md-6">
                    <h3 className={'offerta-' + offerta}>{price.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</h3>
                  </div>
                </div>
                
                <a href={link} target="_blank" className={'btn btn-warning txtoffer-true'}>COGLI QUEST'OFFERTA! <i className="fa-solid fa-external-link"></i></a>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Amazon