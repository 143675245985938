import React, { useState, useEffect } from 'react'
// import { Link } from "react-router-dom"

let search = window.location.search
if (search) {
  var cat1 = search.split("=")[1]
  var cat = cat1.replace('%20', ' ')
  if (cat1 == null) {
    cat = "Best Sellers";
  }
  if (cat === 'PC All%20in%20One') {
    cat = "PC All in One"
  }
  if (cat === 'Best%20sellers') {
    cat = "Best Sellers"
  }
  console.log(cat);
}

const Categoria = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          // "http://localhost:5000/categoria/" + cat + ""
          "https://apis.stefanocoggiola.com/categoria/" + cat + ""
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setData(actualData);
        setError(null);
      } catch(err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }  
    }
    getData()
  }, [])

  return (
    <div className="App">
      <h1>Prodotti tecnologici selezionati per te su Amazon</h1>
      <p>Scegli la categoria di prodotti che ti interessano</p>
      <div className="row m-3">
          <div className="col-md-12 text-center">
              <ul className="list-inline">
              <li className="list-inline-item"><a href="/categoria?cat=Best Sellers" className="menu">I PI&Ugrave; VENDUTI!</a></li>
              <li className="list-inline-item"><a href="/categoria?cat=Accessori" className="menu">Accessori</a></li>
              <li className="list-inline-item"><a href="/categoria?cat=Monitor" className="menu">Monitor</a></li>
              <li className="list-inline-item"><a href="/categoria?cat=PC Desktop" className="menu">PC Desktop</a></li>
              <li className="list-inline-item"><a href="/categoria?cat=PC All in One" className="menu">PC All in One</a></li>
              <li className="list-inline-item"><a href="/categoria?cat=Notebook" className="menu">Notebook</a></li>
              </ul>
          </div>
      </div>
      <h2 className="uppercase">{cat}</h2>
      {loading && <div>Un momento per favore...</div>}
      {error && (
        <div>{`Si è verificato un problema nel caricamento dei dati. Per favore riprova o segnalami l'errore - ${error}`}</div>
      )}
      <div className="row">
        {data &&
          data.map(({_id, title, price, foto, link, cat, sub, offerta, offPrice }) => (            
            <div className="col-sm-3 col-md-2 mb-1 ml-2 cardProdotto" key={_id}>
                {/* <div className={'topoffer-' + offerta}><span className={'txtoffer-' + offerta}>IN OFFERTA OGGI!</span></div> */}
                <h4><a href={link} target="_blank" rel="noreferrer">{title}</a>
                </h4><a href={link} target="_blank" rel="noreferrer">
                {foto ? (
                    <img src={foto} className="fotoCorsi" alt={title} />
                ) : (
                    <img src="/empty-img.jpg" className="fotoCorsi" alt={title} />
                )}</a>
                <br /> <br />
                Categoria: <b>{sub}</b>
                <h2 className={'offerta-' + offerta}>{price.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</h2>
                
                {offPrice ? (
                  <h1 className="offerta">{offPrice.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</h1>
                ) : (
                  <span></span>
                )}

                <a href={link} className={'btn btn-warning txtoffer-' + offerta}>COGLI QUEST'OFFERTA! <i className="fa-solid fa-eye viola"></i></a>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Categoria