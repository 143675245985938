import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import "./App.css";

import Amazon from './components/amazon';
import Categoria from './components/categoria';
// import Corso from './components/corso';

const App = () => {    
  return (
  <>
   <Router>       
      <Routes>
        <Route path='/' element={<Amazon/>} />
        <Route exact path='/categoria' element={<Categoria/>} />
      </Routes>
   </Router>
   </>
  );
}

export default App;